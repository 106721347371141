.b-relation-pages {
  .b-relation-pages_wrap {

  }
  .b-relation-pages_deco {
    img {
      max-width: rem(700px);
    }
  }
  @media (max-width: $to-medium) {
    .o-layout {
      &.-gutter-extra-large {
        margin-left: rem(-$unit);
        .o-layout_item {
          padding-left: rem($unit);
        }
      }
    }
  }
}
