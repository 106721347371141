.b-slider-page {
  position: relative;
  .swiper-wrapper {
    height: 600px;
  }
  .b-slider-page_wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  .c-slider_dots {
    position: relative;
    bottom: 0;
    .swiper-pagination {
      position: relative;
    }
    .swiper-pagination-bullet {
      margin-right: 1.5rem;
      width: rem(15px);
      height: rem(15px);
      background-color: transparent;
      border: rem(1.5px) solid $black;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: $primary;
      border-color: $primary;
    }
  }
}
