.b-etapes-reservation {
  .b-etapes-reservation_wrap {
    .b-etapes-reservation_step {
      font-size: rem(110px);
      font-family: $font-playfair;
      display: flex;
      align-items: flex-end;
      line-height: rem(100px);
      .hr {
        width: 100%;
        height: 2px;
        background-color: $grey;
        margin-left: 1rem;
      }
    }
  }
}
