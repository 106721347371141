.c-tab {
  .c-tab_head {
    background-color: $primary;
    display: flex;
    .c-tab_item {
      padding: 1rem 1.5rem;
      color: $white;
      font-weight: $medium;
      font-size: rem(20px);
      flex: 1;
      text-align: center;
      transition: $speed $easing;
      cursor: pointer;
      border-right: 1px solid $white;
      text-transform: uppercase;
      &.is-active, &:hover {
        background-color: $second;
      }
    }
  }
  .c-tab_body {
    display: grid;
    //background-color: $white;
    .c-tab_item {
      grid-column: 1;
      grid-row: 1;
      display: none;
      &.is-active {
        display: block;
      }
    }
  }
}
