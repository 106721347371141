.b-fichiers {
  .b-fichiers_wrapper {
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
  }
  .b-fichiers-ext {
    font-weight: $light;
    text-transform: uppercase;
  }
  .b-fichiers_wrap {
    display: grid;
    grid-gap: 1rem;
    .b-fichiers_link {
      font-weight: $medium;
      display: grid;
      grid-template-columns: rem(60px) 1fr;
      grid-gap: 1.5rem;
      align-items: center;
      .icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(60px);
        &:after {
          content: "";
          width: rem(50px);
          height: rem(50px);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: $grey-light;
          transition: 0.3s $easing;
          z-index: -1;
          border-radius: 50%;
          transform-origin: center center;
        }
      }
      &:hover {
        color: $second;
        svg {
          * {
            fill: $second;
          }
        }
        .icon {
          &::after {
            background-color: $grey;
            width: rem(60px);
            height: rem(60px);
          }
        }
      }
    }
  }
}
