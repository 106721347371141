.u-c-primary {
  color: $primary;
}
.u-c-second {
  color: $second;
}
.u-c-white {
  color: $white;
  a {
    color: $white;
  }
}
.u-c-grey {
  color: $grey;
}
.u-c-grey2 {
  color: $grey2;
}
.u-c-grey-dark3 {
  color: $grey-dark3;
}
.u-c-green {
  color: $green;
}

.u-bg-primary {
  background-color: $primary;
}
.u-bg-second {
  background-color: $second;
}
.u-bg-grey-light {
  background-color: $grey-light;
}
.u-bg-white {
  background-color: $white;
}
.u-bg-brown {
  background-color: $brown;
}
.u-bg-brown-dark {
  background-color: $brown_dark;
}
.u-bg-black {
  background-color: $black;
}