.c-actu-une {
  position: absolute;
  right: 0;
  top: calc(100vh - #{rem(200px)});
  display: grid;
  grid-template-columns: rem(200px) 1fr;
  grid-template-rows: rem(200px);
  height: rem(200px);
  max-width: rem(768px);
  z-index: 5;
  .c-actu-une_image {

  }
  .c-actu-une_wrap {
    background-color: $white;
    padding: rem($unit-small);
    padding-right: calc(#{rem($unit-small)} + #{rem(100px)} + #{rem($unit-small)});
    padding-bottom: 0;
  }
  @media (max-width: $to-medium) {
    height: rem(170px);
    grid-template-columns: rem(170px) 1fr;
    top: calc(100vh - #{rem(170px)});
  }

  @media (max-width: $to-small) {
    grid-template-columns: rem(80px) 1fr;
    .c-actu-une_wrap {
      padding-right: rem($unit-small);
    }
    .c-button {
      padding: 0;
      margin-top: rem($unit-tiny);
    }
  }
}
