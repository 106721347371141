.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: rem($header-height);
  background: $white;
  z-index: 10;
  box-shadow: rem(0px) rem(10px) rem(30px) $black-overlay-light;
  .c-header_logo {
    display: inline-block;
    max-width: 140px;
  }
  .c-header_nav {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .c-header_nav_burger {

  }
  .c-header_nav_text_anim {
    overflow: hidden;
    height: rem(48px);
    min-width: 75px;
    @media (max-width: $to-small) {
      display: none;
    }
  }
  .c-header_nav_text {
    font-weight: bold;
    color: $black;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    height: 100%;
    &.-hover {
      color: $second;
    }
    @media (max-width: $to-small) {
      display: none;
    }
  }
  .c-header_nav_link {
    padding: rem(20px);
    height: 100%;
    font-family: $playfair;
    font-size: rem(18px);
    font-weight: $bold;
    display: flex;
    align-items: center;
    .burger-span-anim {
      transition: 0.3s ease-in-out;
    }
    &:hover, &.is-active {
      .c-btn_forme {
        fill: $second;
      }
      .c-header_nav_text:not(.-hover) {
        margin-top: rem(-48px);
      }
    }
    &.is-active {
      .burger-span-anim {
        transform: translate(127.843px, 84.082px);
      }
    }
    @media (max-width: $from-small) {
      padding: 0.5rem;
    }
  }
  .c-header_nav_forme {
    margin-right: rem(10px);
    svg {
      max-width: 50px;
    }
    .c-btn_forme {
      transition: fill 0.3s ease-in-out;
    }
    @media (max-width: $from-small) {
      margin-right: 0;
    }
  }
  .c-header_nav_count {
    font-family: $roboto;
    font-size: rem(18px);
    font-weight: $light;
    color: $black;
    margin-left: rem(10px);
  }
  @media (min-width: $from-small) {
    padding: 0 rem(40px);
  }
  @media (max-width: $to-small) {
    padding: 0 rem(20px);
  }
}
