.c-addtocart {
  display: flex;
  .c-addtocart_qte {
    display: flex;
    align-items: center;
    background-color: $grey-light;
    border-radius: 10px 0 0 10px;
    padding: rem(15px) rem(20px);
    .c-addtocart_label {
      margin-right: 1.5rem;
      font-weight: $light;
    }
  }
}
