.b-cta {
  position: relative;
  .u-c-primary {
    transition: .3s $Power1EaseInOut;
  }
  .c-heading {
    line-height: 1.5;
  }
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(400px);
    height: rem(400px);
    content: '';
    border-radius: 50%;
    background-color: $grey-light;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: .3s $Power1EaseInOut;
  }
  &:hover {
    .u-c-primary {
      color: $second;
    }
    .c-button_label {
      .c-button_label_inner {
        opacity: 0;
        transform: translateY(-1.875rem);
        transition-delay: 0s;
      }
      &::after {
        opacity: 1;
        transform: translateY(0);
        transition-delay: .1s;
      }
    }
    &::after {
      width: rem(450px);
      height: rem(450px);
    }
  }
}
