.c-card {
  position: relative;
  &.-page {
    @media (max-width: $to-small) {
      transform: none!important;
    }
    .c-card_number {
      font-weight: $medium;
    }
    .c-card_image {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      .c-figure {
        width: 100%;
        height: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to right, $degradecard, $degradecard2);
        //mix-blend-mode: exclusion;
        opacity: 0.65;
        z-index: 1;
      }
      img {
        transition: 0.3s $easing;
      }
    }
    .c-card_wrap {
      z-index: 2;
      padding: 5rem 4rem;
      @media (max-width: $to-medium) {
        padding: 3rem 2rem;
      }
    }
    .c-button {
      text-align: left;
      padding: 0;
    }
    .c-heading {
      max-width: 80%;
    }
    &:hover {
      .c-card_image {
        overflow: hidden;
        img {
          transform: scale(1.05);
        }
      }
      .c-button_label {
        .c-button_label_inner {
          opacity: 0;
          transform: translateY(-1.875rem);
          transition-delay: 0s;
        }
        &::after {
          opacity: 1;
          transform: translateY(0);
          transition-delay: .1s;
        }
      }
    }
  }
  &.-page-round {
    border-radius: 50%;
    overflow: hidden;
    a {
      display: grid;
    }
    .c-card_image {
      grid-column: 1;
      grid-row: 1;
      z-index: 1;
      background-color: $white;
      padding-bottom: 100%;
      .c-figure {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 193.5px solid $white;
          transition: 0.5s $easing;
          z-index: 3;
          border-radius: 50%;
        }
        &:after {
          content: "";
          position: absolute;
          top: 1px;
          left: 1px;
          right: 1px;
          bottom: 1px;
          background: linear-gradient(to right, $grey-dark, $grey-dark2);
          //mix-blend-mode: exclusion;
          opacity: 0.55;
          z-index: 2;
          border-radius:50%;
        }
        img {
          border-radius: 50%;
          border: 1px solid $white;
        }
      }
      .c-figure {
        /*transform: scale(0);
        transform-origin: center center;
        transition: 0.5s $easing;*/
        border-radius: 50%;
        overflow: hidden;
      }

    }
    .c-card_wrap {
      position: relative;
      padding: 4vw;
      grid-column: 1;
      grid-row: 1;
      z-index: 3;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .u-c-primary {
      transition: 0.5s $easing;
    }
    &:hover {
      .u-c-primary {
        color: $white;
      }
      .c-card_image {
        /*.c-figure {
          transform: scale(1);
        }*/
        .c-figure {
          .before {
            border-width: 0!important;
          }
        }
      }
    }
  }
  &.-offset {
    top: rem($unit-extra-large);
  }
  &.-margin-large {
    margin-bottom: rem($unit-large);
  }
  &.-margin-extra-large {
    margin-bottom: rem($unit-extra-large);
  }
  @media (max-width: $to-medium) {
    &.-offset {
      top: rem($unit);
    }
  }

  @media (max-width: $to-small) {
    &.-offset {
      top: 0;
    }
  }

  &.-point-vente {
    background-color: $grey-light;
    padding: 1rem 0;
    display: grid;
    grid-template-columns: rem(180px) 1fr;
    overflow: hidden;
    align-items: center;
    .icon {
      margin-left: -1.5rem;
      svg {
        max-height: 200px;
      }
    }
    @media (max-width: $to-small) {
      grid-template-columns: rem(100px) 1fr;
      .icon {
        margin-left: -3.5rem;
        svg {
          max-height: 100px;
        }
      }
    }
  }
  &.-point-vente-detail {
    .c-card_wrap {
      display: flex;
      @media (max-width: $to-small) {
        flex-direction: column;
      }
    }
    .c-card_details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $primary;
      color: $white;
      flex: 1;
    }
    .c-card_contact {
      a {
        color: $white;
      }
    }
    .c-card_hours {
      flex: 0.5;
      background-color: $grey-light;
      display: grid;
      grid-gap: 0.5rem;
      font-size: rem(14px);
      line-height: 1.1;
    }
    .c-card_map {
      flex: 1;
      .acf-map {
        width: 100%;
        min-height: 200px;
        height: 100%;
      }

      // Fixes potential theme css conflict.
      .acf-map img {
       max-width: inherit !important;
      }
    }
  }
  &.-actualite {
    &.-margin-top {
      margin-top: $unit-large;
      @media (max-width: $to-medium) {
        margin-top: rem($unit-small);
      }
    }
    a {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 2.5rem;
      align-items: center;
      @media (max-width: $to-small) {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
      }
    }
    .c-card_image {
      overflow: hidden;
      width: 300px;
      height: 300px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $degrade2;
        opacity: 0;
        transition: 0.3s $easing;
        z-index: 1;
      }
      figure {
        height: 100%;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: 0.3s $easing;
        }
      }
    }
    .c-card_date {
      color: $grey-dark3;
      text-transform: uppercase;
      font-weight: $normal;
      letter-spacing: 0.32px;
      font-size: rem(14px);
      margin-bottom: 1rem;
    }
    .c-card_tags {
      position: relative;
      .icon {
        position: absolute;
        left: rem(-5px);
        z-index: -1;
      }
    }
    .c-heading {
      transition: 0.3s $easing;
    }
    &:hover {
      .c-heading {
        .icon {
          svg {
            path {
              fill: $second;
            }
          }
        }
        &.-h5 {
          color: $second;
        }
      }
      .c-card_image {
        figure {
          img {
            transform: scale(1.05);
          }
        }
        &::after {
          opacity: 0.6;
        }
      }
      a {
        color: inherit;
      }
    }
  }
  &.-produit {
    margin-bottom: $unit-large;
    text-align: center;
    .c-card_image {
      position: relative;
      overflow: hidden;
      .c-card_image_hover {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        //opacity: 0;
        transition: 0.3s $easing;
        line-height: 1;
        width: 100%;
        span {
          background-color: $primary;
          color: $white;
          padding: 0.5rem 1rem;
          display: inline-block;
        }
      }
    }
    .c-card_wrap {
      .c-card_wrap_excerpt {
        color: $grey2;
        font-weight: $light;
      }
      .c-card_wrap_price {
        text-align: center;
        font-weight: $bold;
      }
    }
    &:hover {
      .c-card_image {
        .c-card_image_hover {
          //opacity: 1;
          transform: translateY(0);
        }
      }
      .c-card_wrap {
        .c-card_wrap_excerpt {
          color: $grey2;
        }
      }
    }
  }
}
