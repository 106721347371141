// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$black: #1A1610;
$primary: #EFC245;
$second: #E38E19;
$green: #77C483;
$red: #D1603E;
$grey: #DEDEDE;
$grey2: #808080;
$grey-light: #F7F7F7;
$black-overlay-light: rgba(0, 0, 0, 0.5);
$degrade1: rgba(95, 95, 95, 0.6);
$degrade2: rgba(43,43,43, 0.49);
$degradecard: rgba(43,43,43, 1);
$degradecard2: rgba(83, 83, 83, 1);
$grey-dark: #4D4D4D;
$grey-dark2: #717171;
$grey-dark3: #AEAEAE;
$brown: #312F2C;
$brown-dark: #1D1C1A;

// Specific
// =============================================================================
// Link
$link-color:       $black;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color:       $primary;
$selection-background-color: #FFFFFF;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
