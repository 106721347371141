.c-footer {
  .c-footer_wrap {
    background-color: $brown;
    .c-footer_logo {
      padding-bottom: 2rem;
      border-bottom: 1px solid $grey-dark2;
      display: inline-block;
    }
    .c-footer_coord-title {
      font-weight: $medium;
      text-transform: uppercase;
    }
    .c-heading {
      margin-bottom: 2.5rem;
    }
    .c-footer_contacts {
      color: $grey-dark3;
      div {
        margin-bottom: 0.5rem;
      }
    }
    .c-footer_nav {

    }
    .c-footer_link {
      color: $grey-dark3;
      display: block;
      font-weight: $normal;
      margin-bottom: 0.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      &:hover {
        color: $white;
      }
    }
  }
  .c-footer_bottom {
    background-color: $brown-dark;
    font-weight: $light;
    * {
      color: $grey-dark3;
    }
    .c-footer_bottom_links {
      text-align: right;
      .c-footer_bottom_link {
        &:hover {
          color: $white;
        }
      }
    }
  }
}
