.c-widget-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  padding: rem($unit);
  justify-content: center;
  align-items: center;
  z-index: 11;
  .c-widget-menu_bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $black-overlay-light;
      z-index: -1;
    }
  }
  .c-widget-menu_wrap {
    background-color: $white;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 30px;
    display: grid;
    width: 100%;
    max-width: rem($container-large-width);
    max-height: 90%;
    overflow: auto;
  }
  .c-widget-menu_communes {
    grid-column: 1;
    grid-row: 1;
  }
  .c-widget-menu_item {
    grid-column: 1;
    grid-row: 2;
    display: none;
    &.is-active {
      display: block;
    }
  }
  .c-widget-menu_head {
    padding: rem($unit-small) rem($unit);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .c-widget-menu-infos {
    font-weight: $light;
  }
  .c-widget-menu_body {

  }
  .c-widget-menu_foot {
    padding: rem($unit-small) rem($unit);
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .c-widget-menu_close {
    position: relative;
    width: rem(75px);
    height: rem(75px);
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(60px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $primary;
      transition: $speed $easing;
      border-radius: 50%;
    }
    span {
      position: relative;
      z-index: 2;
    }
    &:hover {
      &::after {
        width: 100%;
        height: 100%;
        background-color: $second;
      }
    }
  }

  .c-widget-menu_menu-row {
    display: grid;
    align-items: center;
    grid-template-columns: rem(80px) 1fr;
    .title {
      text-transform: uppercase;
    }
  }
  
  /*.c-tab_body {
    hr {
      &:last-of-type {
        display: none;
      }
    }
  }*/
  
}
.accompagnements {
  font-weight: $bold;
  font-family: $font-playfair;
  font-size: rem(20px);
}

.c-widget-menu_head_actions {
  display: flex;
  align-items: center;
  .c-widget-menu_pagination {
    margin-right: 1.5rem;
    .c-widget-menu_pagination_btn {
      &.disabled {
        cursor: default;
        color: $grey;
      }
    }
    .-prev {
      margin-right: 1rem;
      svg {
        transform: rotate(180deg);
      }
    }
    button {
      &:hover {
        color: $second;
      }
    }
  }
}

.c-widget-menu_menu-btn {
  border: 3px solid $grey;
  border-radius: 9px;
  transition: $speed $easing;
  //padding: 0.5rem 1.5rem;
  margin-right: rem($unit-small);
  position: relative;
  display: inline-block;
  cursor: pointer;
  .c-widget-menu_menu-btn_letter {
    position: absolute;
    top: 0;
    left: 1rem;
    background-color: $white;
    transform: translateY(-50%);
    padding: 0 0.5rem;
    font-size: rem(14px);
    transition: $speed $easing;
  }
  .c-widget-menu_menu-btn_label {
    color: $black;
    font-weight: $bold;
    font-family: $font-playfair;
    font-size: rem(20px);
    opacity: 0.7;
    transition: $speed $easing;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    display: block;
  }
  &.is-active, &:hover {
    border-color: $second;
    .c-widget-menu_menu-btn_label {
      color: $second;
      opacity: 1;
    }
    .c-wideget-menu_menu-btn_letter {
      color: $second;
    }
  }
  &:hover {
    .c-widget-menu_menu-btn_label {
      color: $black;
      opacity: 1;
    }
  }
}

.c-widget-menu_body {
  .c-tab_head {
    .c-tab_item {
      position: relative;
      .nb {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.5rem;
        height: 1.5rem;
        font-size: rem(18px);
        background: $red;
        border-radius: 50%;
        //display: flex;
        align-items: center;
        justify-content: center;
        display: none;
        &.is-show {
          display: flex;
        }
      }
    }
  }
}

.qte {
  background-color: $grey-light;
  padding: 0.5rem 1rem;
  max-width: rem(100px);
}

.c-widget-menu_item-cart {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: rem($unit-small);
}

.menu-widget-item {
  display: grid;
  grid-template-columns: rem(80px) 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  overflow: hidden;
  background-color: $grey-light;
  border: 2px solid $grey;
  border-radius: 9px;
  transition: $speed $easing;
  cursor: pointer;
  &:hover, &.is-active {
    border-color: $primary;
  }
  .menu-widget-item_icon {
    position: relative;
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .menu-widget-item_body {
    .menu-widget-item_body_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menu-widget-item_body_head_qte {
        form {
          display: flex;
          align-items: center;
        }
        input {
          background-color: $grey-light;
          padding: 0.5rem 1rem;
        }
      }
    }
  }
  .menu-widget-item_delete-btn {
    color: $grey2;
    &:hover {
      color: $black;
    }
  }
}
.menu-widget-item {
  &.-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $grey;
    padding: rem($unit) rem($unit-small);
    color: $black;
    transition: $speed $easing;
    cursor: pointer;
    &:hover {
      background-color: $grey-dark3;
    }
  }
}

.has-menuOpen {
  .c-widget-menu {
    display: flex;
  }
}
.c-widget_cart_item {
  border: 1px solid $primary;
  border-radius: 10px;
  overflow: hidden;
  .head {
    background-color: $primary;
    color: $white;
    padding: 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .c-accordion_item {
    padding: 0 1rem 1rem;
    .c-accordion_header {
      padding: 1rem 0;
      transition: $speed $easing;
    }
    .c-accordion_main {
      padding-top: rem($unit-small);
      display: grid;
      grid-gap: rem($unit-small);
    }
    &.is-open {
      .c-accordion_header {
        border-bottom: 2px solid $primary;
        color: $primary;
      }
    }
  }
  .c-widget_cart_item_details_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .c-widget_cart_item_details_row {
    display: grid;
    grid-template-columns: rem(100px) 1fr;
    grid-gap: 1rem;
    margin-bottom: 0.25rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.c-widget-menu-page {
  .c-widget-menu-page_wrap {
    display: grid;
    border-radius: rem(10px);
    overflow: hidden;
    @media (max-width: $from-small) {
      overflow: auto;
    }
  }
  
  .c-widget-menu_item {
    grid-column: 1;
    grid-row: 2;
    display: none;
    &.is-active {
      display: block;
    }
  }
  .c-widget-menu_head {
    padding: rem($unit-tiny) rem($unit-small);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-light;
  }
}

.c-widget-menu_cart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 11;
  justify-content: flex-end;
  .c-widget-menu_bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $black-overlay-light;
      z-index: -1;
    }
  }

  .c-widget-menu_wrap {
    background-color: $white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: rem(550px);
    overflow: auto;
    .c-widget-menu_content {
      padding: rem($unit);
    }
    .c-widget-menu_foot {
      border-top: 2px solid $grey-light;
    }
  }


  &.is-open {
    display: flex;
  }
}

.menu-widget-cart-item {
  background-color: $grey-light;
  border-radius: rem(10px);
  .menu-widget-item_body_head {
    padding-bottom: rem($unit-tiny);
    border-bottom: 1px solid $grey;
  }
  .menu-widget-item_content {
    display: grid;
    grid-template-columns: rem(100px) 1fr;
    grid-gap: rem($unit-tiny);
    align-items: center;
  }
}

.c-widget-cart_total {
  display: flex;
  align-items: center;
  justify-content: center;
  .total {
    font-size: rem($font-size-h5);
  }
}

.fixed-element {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 8;
}

.c-widget-menu_body_menuwrap {
  display: grid;
  grid-template-columns: 1fr rem(40px) 1fr;
  grid-gap: rem($unit-tiny);
  .c-widget-menu_or {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 80%;
      background-color: $primary;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      content: "";
      position: absolute;
      background-color: $grey-light;
      width: rem(60px);
      height: rem(60px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
    .c-widget-menu_or_round {
      width: rem(40px);
      height: rem(40px);
      background-color: $primary;
      color: $white;
      font-weight: $bold;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
    }
  }
  .c-widget-menu_form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: $to-medium) {
    grid-template-columns: 1fr;
  }
}

