.c-slider {
  position: relative;
  max-width: 100vw;
  //overflow: hidden;
  .c-slider_slide {
    display: grid;
    min-height: calc(100vh - #{rem($header-height)});
    max-height: calc(100vh - #{rem($header-height)});
    //overflow: hidden;
    height: 100%;
    .c-slider_img, .c-slider_content {
      grid-column: 1;
      grid-row: 1;
    }
    .c-slider_img {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(to bottom, $degrade1, $degrade2);
      }
    }
    .c-slider_content {
      z-index: 2;
      //padding: 8rem 0;
      color: $white;
      .o-container, .o-layout {
        height: 100%;
      }
      .o-container {
        max-width: rem(1300px);
      }
      .c-heading {
        text-shadow: 0 rem(5px) rem(10px) $black-overlay-light;
      }
      @media (max-width: $to-large) {
        .o-layout.-middle {
          align-items: flex-start;
        }
      }
    }
  }
}
.c-slider_container {
  position: relative;
  .c-slider_dots {
    position: absolute;
    bottom: rem($unit-large);
    left: 0;
    width: 100%;
    z-index: 5;
  }
  .swiper-pagination {
    position: relative;
    text-align: left;
    .swiper-pagination-bullet {
      margin-right: 1.5rem;
      width: rem(15px);
      height: rem(15px);
      background-color: transparent;
      border: rem(1.5px) solid $white;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: $primary;
      border-color: $primary;
    }
  }
}
