.b-produits-listing {
  .c-accordion_item {
    .c-accordion_header {
      padding: 1rem 0;
      .c-accordion_header-title {
        display: grid;
        grid-template-columns: 48px 1fr;
        grid-gap: 1.5rem;
      }
      .c-accordion_header-heading {
        font-size: rem(20px);
        font-weight: $medium;
      }
      &:hover, &.is-active {
        a {
          color: $primary;
        }
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
    .c-accordion_main_wrap-content {
      display: grid;
      grid-gap: 0.5rem;
      padding-left: calc(48px + 1.5rem);
      a {
        &:hover, &.is-active {
          color: $primary;
        }
      }
    }
  }
  .b-produits-listing_head {
    text-transform: uppercase;
  }
  button {
    text-align: left;
    &:hover {
      color: $second;
    }
  }
}
