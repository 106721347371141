.b-newsletter {
  border-top: 1px solid $grey;
  .c-form {
    display: flex;
    .c-form_item {
      width: 100%;
      input {
        width: 100%;
      }
    }
    button {
      background-color: $grey-light;
      padding: 0 1.5rem;
    }
  }
}
