.c-nav {
  position: fixed;
  top: rem(112px);
  left: 0;
  right: 0;
  z-index: 9;
  //visibility: hidden;
  transform: translateY(-100%);
  transition: transform 0.3s $easing;
  height: calc(100vh - 112px);
  .c-nav_bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $black-overlay-light;
      z-index: -1;
    }
  }
  .c-nav_wrap {
    background-color: $white;
    overflow-x: auto;
    max-height: calc(100vh - 112px);
  }
  .c-nav_image-bg {
    transform: translateY(-100%);
    transition: 0.3s $easing;
    transition-delay: 0.7s;
    @media (max-width: $to-small) {
      display: none;
    }
  }
  .c-nav_primary {
    display: grid;
    grid-gap: 1rem;
    opacity: 0;
    transform: translateX(-200px);
    transition: 0.3s $easing;
    transition-delay: 0.3s;
    .c-nav_primary_link {
      position: relative;
      .deco {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: -1;
      }
    }
    .is-active {
      color: $primary;
    }
  }
  .c-nav_shop {
    opacity: 0;
    transform: translateY(100%);
    transition: 0.3s $easing;
    transition-delay: 0.5s;
    .icon {
      svg {
        max-width: 30px;
      }
    }
  }
}

.has-navOpen {
  .c-nav {
    //visibility: visible;
    transform: translateY(0);
    .c-nav_primary {
      opacity: 1;
      transform: translateX(0);
    }
    .c-nav_shop {
      opacity: 1;
      transform: translateY(0);
    }
    .c-nav_image-bg {
      transform: translateY(0);
    }
  }
}
