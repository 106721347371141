.c-cart {
  .c-cart_cart {
    .-produit {
      flex: 3;
    }
    .-qte {
      text-align: center;
    }
    .-prix {
      text-align: right;
    }
    .-supp {
      text-align: right;
      a {
        color: $grey2;
        svg {
          width: rem(18px);
        }
        &:hover {
          color: $second;
        }
      }
    }
  }
}
