.b-icones {
  .b-icones_iconWrap {
    &.-margin {
      margin-bottom: rem($unit-large);
    }
    .icon {
      max-height: 140px;
    }
  }
}
