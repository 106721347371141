.c-button {
    padding: rem(15px) rem(20px);
    overflow: hidden;
    font-family: $playfair;
    font-size: rem(18px);
    font-weight: $bold;
    .c-button_label {
      position: relative;
      display: inline-block;
      z-index: 2;
      .c-button_label_inner {
        position: relative;
        display: inline-block;
        transition: transform .3s cubic-bezier(.35,.755,.42,.95) .1s, opacity .3s cubic-bezier(.35,.755,.42,.95) .1s;
        transform-origin: left top;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        content: attr(data-text);
        transition: transform .3s cubic-bezier(.35,.755,.42,.95),opacity .3s cubic-bezier(.35,.755,.42,.95);
        opacity: 0;
        transform: translateY(1.875rem);
        color: $second;
        transform-origin: left top;
      }
    }
    .icon {
      margin-left: 1rem;
      svg {
        max-width: 50px;
      }
    }

    &.-white {
      .c-button_label {
        .c-button_label_inner {
          color: $white;
        }
        &::after {
          color: $white;
        }
      }
    }

    &.-black-white {
      .c-button_label {
        .c-button_label_inner {
          color: $black;
        }
        &::after {
          color: $white;
        }
      }
    }

    @include u-hocus {
      .c-button_label {
        .c-button_label_inner {
          opacity: 0;
          transform: translateY(-1.875rem);
          transition-delay: 0s;
        }
        &::after {
          opacity: 1;
          transform: translateY(0);
          transition-delay: .1s;
        }
      }
    }
}

.c-button-full {
  padding: rem(15px) rem(20px);
  background-color: $primary;
  color: $white;
  transition: 0.3s $easing;
  font-weight: $medium;
  display: flex;
  align-items: center;
  .icon {
    margin-right: 1rem;
  }
  @include u-hocus {
    background-color: $second;
  }
  &.-addtocart {
    border-radius: 0px 10px 10px 0px;
  }
}

.c-button-round {
  display: grid;
  overflow: hidden;
  border-radius: 50%;
  max-width: 500px;
  .c-button-round_round {
    grid-column: 1;
    grid-row: 1;
    padding-bottom: 100%;
    border-radius: 50%;
    background-color: $grey-light;
    transform-origin: center center;
    transform: scale(0.8);
    transition: 0.3s $easing;
  }
  .c-button-round_btn {
    grid-column: 1;
    grid-row: 1;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s $easing;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  &:hover {
    .c-button-round_round {
      transform: scale(1);
      background-color: $grey;
    }
    .u-c-primary {
      color: $second;
    }
  }
}

.c-button-round-picto {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $second;
  color: $white;
  width: rem(35px);
  height: rem(35px);
  border-radius: 50%;
  transition: $speed $easing;
  .c-button-round-picto_icon {
    svg {
      max-height: rem(20px);
    }
  }
  &.-grey-light {
    background-color: $grey-light;
    color: $black;
  }
  &:hover {
    transform: scale(1.1);
  }
}