.c-checkout_thankyou {
  .c-checkout_thankyou_valid {
    width: rem(100px);
    height: rem(100px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: $green;
    border-radius: 50%;
    margin: 0 auto;
  }
}
.woocommerce-notices-wrapper {
  display: none;
}
