.b-tiroirs-image {
  .c-accordion_header {
    justify-content: flex-start;
    align-items: center;
    .icon {
      margin-right: 2rem;
      svg {
        max-height: rem(80px);
        path {
          transition: .3s $easing;
        }
      }
      @media (max-width: $to-small) {
        margin-right: 0.5rem;
        svg {
          max-height: rem(40px);
        }
      }
    }
    .c-heading {
      transition: .3s $easing;
    }
    .c-accordion_plus {
      margin-left: auto;
    }
    &:hover {
      .icon {
        svg {
          path {
            fill: $second;
          }
        }
      }
      .c-heading {
        color: $second;
      }
    }
  }
  .is-open {
    .c-accordion_header {
      .icon {
        svg {
          path {
            fill: $second;
          }
        }
      }
      .c-heading {
        color: $second;
      }
    }
  }
  .b-tiroirs-image_wrap {
    position: relative;
    .b-tiroirs-image_image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      @media (max-width: $to-medium) {
        position: relative;
        max-width: 80vw;
      }
    }
  }
  @media (max-width: $to-medium) {
    display: grid;
    .b-tiroirs-image_wrap {
      order: 3;
    }
  }
}
