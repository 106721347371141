.c-heading {
    line-height: $line-height-h;
    margin-bottom: rem(18px);

    &.-noMargin {
      margin-bottom: 0;
    }

    &.-h1 {
        font-size: rem($font-size-h1);
        font-family: $font-playfair;
        font-weight: $bold;
        line-height: $line-height-default;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h1)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h1)} - 20px);
        }
    }

    &.-h1-c {
        font-size: rem($font-size-h1-c);
        font-family: $font-charlotte;
        line-height: $line-height-default;
        position: relative;
        padding-left: 3rem;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 1rem;
          height: rem(4px);
          background-color: $white;
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h1-c)} - 25px);
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        font-family: $font-playfair;
        font-weight: $bold;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h2)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h2)} - 15px);
        }
    }

    &.-h2-c {
        font-family: $font-charlotte;
        font-size: rem($font-size-h2-c);
        position: relative;
        padding-left: 3rem;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 1rem;
          height: rem(4px);
          background-color: $primary;
        }
        &.-center {
          padding-left: 0;
          padding-top: 1rem;
          &::before {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
    }

    &.-h3-c {
        font-size: rem($font-size-h3-c);
        font-family: $font-charlotte;
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        font-family: $font-playfair;
        font-weight: $bold;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h3)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h3)} - 15px);
        }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
        font-family: $font-playfair;
        font-weight: $bold;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h4)} - 10px);
        }
    }

    &.-h5 {
        font-size: rem($font-size-h5);
        font-family: $font-playfair;
        font-weight: $bold;
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h5)} - 7px);
        }
    }

    &.-h6 {
        font-size: rem($font-size-h6);
        font-weight: $medium;
        letter-spacing: 0.8px;
        &:not(.-nouppercase) {
          text-transform: uppercase;
        }
    }

    &.-border {
      padding-bottom: rem($unit-tiny);
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: $black;
      }
      &.u-c-primary {
        &::after {
          background-color: $primary;
        }
      }
    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
