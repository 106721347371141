.b-form-contact {
  .b-form-contact_contacts {
    display: flex;
    flex-direction: column;
    height: 100%;
    .b-form-contact_contacts-image {
      flex: 3;
    }
    .b-form-contact_contacts-points-vente {
      flex: 1;
    }
  }
}
