.c-product {
  .c-product_galerie {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: rem($unit-small);
    height: 100%;
    min-height: 300px;
    .c-product_galerie_nav {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: rem($unit-small);
    }
    .c-product_galerie_wrap {

    }
    .img-link {
      cursor: pointer;
    }
  }
  .c-product_price {
    font-size: rem($font-size-h4);
    font-weight: $light;
  }
}
